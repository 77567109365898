<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12" v-if="resource">
          <ion-list>
            <ion-list-header> Invoice #: {{ resource.id }} </ion-list-header>
            <ion-item v-if="resource.payment">
              <ion-label>
                payment {{ resource.payment.status }} on
                {{ new Date(resource.payment.updated_at).toDateString() }}
              </ion-label>
            </ion-item>
            <ion-item>
              <!-- <ion-buttons> -->
              <ion-button
                :href="`${base_url}api/v1/fee-invoice-print/${resource.id}?pat=${token}`"
                :download="`fee_invoice_${resource.id}`"
                color="success"
              >
                Invoice
                <ion-icon
                  :ios="documentOutline"
                  :md="documentSharp"
                  size="small"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>

              <ion-button
                :href="`${base_url}api/v1/fee-invoice-receipt/${resource.id}?pat=${token}`"
                :download="`fee_invoice_receipt_${resource.id}`"
                :disabled="receiptStatus()"
              >
                Receipt
                <ion-icon
                  :ios="documentOutline"
                  :md="documentSharp"
                  size="small"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>
              <!-- </ion-buttons> -->
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Created:
                {{ new Date(resource.bill_fee.bill.created_at).toDateString() }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Due:
                {{
                  new Date(resource.bill_fee.bill.bill_due_date).toDateString()
                }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                From:
                {{
                  new Date(resource.bill_fee.bill.bill_from_date).toDateString()
                }}
                to
                {{
                  new Date(resource.bill_fee.bill.bill_to_date).toDateString()
                }}
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list>
            <ion-list-header>
              {{ resource.user.user_detail.name }}
            </ion-list-header>
            <ion-item lines="none">
              <ion-label>
                {{ resource.user.email }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Class {{ resource.standard.name }} for Session:
                {{ resource.bill_fee.bill.session.name }}
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-for="item in resource.fee_invoice_items" :key="item.id">
            <ion-list-header>
              {{ item.name }}
            </ion-list-header>
            <ion-item>
              <ion-label>
                Net Charge: Rs {{ item.pivot.amount_in_cent / 100 }}/-
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                Tax: Rs
                {{ (item.pivot.gross_amount_in_cent - item.pivot.amount_in_cent) / 100 }}/-
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list>
            <ion-item lines="none">
              <ion-label>
                Total: Rs {{ resource.gross_amount_in_cent / 100 }}/-
              </ion-label>
            </ion-item>
          </ion-list>

          <!-- <ion-button
            type="button"
            expand="full"
            class="ion-margin-vertical"
            @click="showRazorpay"
            :disabled="payButtonStatus()"
          >
            {{ payButton }}
          </ion-button> -->
          <ion-button
            @click="presentActionSheet()"
            type="button"
            expand="full"
            class="ion-margin-vertical"
            >Payment</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-modal
      :is-open="offlinePaymentModalState"
      @didDismiss="setOfflinePaymentModalState(false)"
    >
      <base-modal
        title="Update Offline Payment"
        @closeModal="setOfflinePaymentModalState(false)"
      >
        <offline-payment
          @closeModal="setOfflinePaymentModalState(false)"
          :resource="resource"
        ></offline-payment>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import OfflinePayment from "../../Components/Due/Invoice/OfflinePayment";
import base_url from "../../../apis/base_url";
import "capacitor-razorpay";
import { Plugins } from "@capacitor/core";
const { Checkout } = Plugins;

import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonButton,
  IonIcon,
  alertController,
  actionSheetController,
} from "@ionic/vue";

import { documentOutline, documentSharp } from "ionicons/icons";

export default {
  props: ["invoice"],
  components: {
    BaseLayout,
    BaseModal,
    OfflinePayment,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonButton,
    IonIcon,
  },

  data() {
    return {
      title: "Invoice",
      backLink: "invoice",
      reloadable: false,

      offlinePaymentModalState: false,

      documentOutline,
      documentSharp,
      base_url,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    token() {
      return this.$store.getters["auth/token"];
    },
    resource() {
      return this.$store.getters["invoice/showResource"];
    },

    razorpay() {
      return this.$store.getters["razorpay/showResource"];
    },

    payButton() {
      const payment = this.resource.payment;
      let text = "Pay";
      if (payment) {
        switch (payment.status) {
          case "created":
            text = "Created";
            break;
          case "captured":
            text = "Paid(captured)";
            break;
          default:
            text = "Payment processing";
        }
      }
      return text;
    },
  },

  methods: {
    alerter(header, message) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: ["Close"],
      });
      return alert;
    },

    confirmPasswordAlert(header) {
      const alert = alertController.create({
        header: header,
        inputs: [
          {
            name: "password",
            id: "password",
            type: "password",
          },
        ],
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Ok",
            handler: async (data) => {
              const username = await this.$store.getters["auth/user"].username;
              const credentials = {
                username,
                password: data.password,
              };
              try {
                await this.$store.dispatch("auth/login", credentials);
                this.setOfflinePaymentModalState(true);
              } catch (error) {
                this.setErrorResponse(error);
                const alertDialog = await this.alerter(
                  this.xhrError.header,
                  this.xhrError.message
                );
                await alertDialog.present();
              }
            },
          },
        ],
      });
      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    rzpButtonStatus() {
      const payment = this.resource.payment;
      let disabled_status = true;
      if (!payment) {
        disabled_status = false;
      }
      if (payment) {
        const failed =
          payment.status === "failed" || payment.status === "created"
            ? true
            : false;

        disabled_status = !failed;
      }
      return disabled_status;
    },

    receiptStatus() {
      const payment = this.resource.payment;
      let disabled_status = true;
      if (payment) {
        const captured = payment.status === "captured" ? true : false;
        disabled_status = !captured;
      }
      return disabled_status;
    },

    async tryOnlinePayment() {
      const disabled = this.rzpButtonStatus();
      if (disabled) {
        const alertDialog = await this.alerter(
          "Alert",
          `Payment status: ${this.payButton}`
        );
        await alertDialog.present();
      } else {
        await this.showRazorpay();
      }
    },

    async tryOfflinePayment() {
      const disabled = this.rzpButtonStatus();
      if (disabled) {
        const alertDialog = await this.alerter(
          "Alert",
          `Payment status: ${this.payButton}`
        );
        await alertDialog.present();
      } else {
        const confirm = await this.confirmPasswordAlert("Confirm Password");
        await confirm.present();
      }
    },

    async showRazorpay() {
      await this.fetchRazorpay();

      const options = {
        order_id: this.razorpay.razorpayData.order_id,
        currency: this.razorpay.razorpayData.currency,
        amount: this.razorpay.razorpayData.amount.toString(),

        key: this.razorpay.razorpayData.key,
        name: this.razorpay.razorpayData.name,
        description: this.razorpay.razorpayData.description,
        image: this.razorpay.razorpayData.image,

        prefill: {
          name: this.razorpay.user.user_detail.name,
          email: this.razorpay.user.email,
          contact: this.razorpay.user.user_detail.phone,
        },
      };

      try {
        let data = await Checkout.open(options);
        this.verifyPayment(data.response);
      } catch (error) {
        console.warn(error["description"]);
      }
    },

    async verifyPayment(data) {
      try {
        await this.$store.dispatch("razorpay/verifyPayment", {
          payment_id: data.razorpay_payment_id,
          signature: data.razorpay_signature,
          invoice_id: this.invoice,
        });

        this.fetchResource();
        this.$router.replace({
          name: "payment.successfull",
          invoice: this.invoice,
        });
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        await alertDialog.present();
      }
    },

    async fetchResource() {
      await this.$store.dispatch("invoice/showResource", {
        resourceId: this.invoice,
      });
    },

    async fetchRazorpay() {
      try {
        await this.$store.dispatch("razorpay/showResource", {
          resourceId: this.invoice,
        });
      } catch (error) {
        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        await alertDialog.present();
      }
    },

    async presentActionSheet() {
      const actionSheet = await actionSheetController.create({
        header: "Payment methods",
        buttons: [
          {
            text: "Online (Razorpay)",
            handler: () => {
              this.tryOnlinePayment();
            },
          },
          {
            text: "Offline Payment",
            handler: () => {
              this.tryOfflinePayment();
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    },

    setOfflinePaymentModalState(state) {
      this.offlinePaymentModalState = state;
    },
  },

  async created() {
    await this.fetchResource();
  },
};
</script>

<style scoped></style>
