<template>
  <form @submit.prevent="setOfflinePayment()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-list-header> Invoice #: {{ resource.id }} </ion-list-header>
          <ion-item lines="none">
            <ion-label>
              Total: Rs {{ resource.gross_amount_in_cent / 100 }}/-
            </ion-label>
          </ion-item>
          <ion-list-header>
            For, <br />
            {{ resource.user.user_detail.name }}
          </ion-list-header>
          <ion-item lines="none">
            <ion-label>
              Class {{ resource.standard.name }} for Session:
              {{ resource.bill_fee.bill.session.name }}
            </ion-label>
          </ion-item>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.amount.$error }">
            <ion-label position="floating">Amount Received</ion-label>
            <ion-input type="number" v-model="amount"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.instrument.$error }">
            <ion-label position="floating">Instrument</ion-label>
            <ion-select v-model="instrument" :value="instrument">
              <ion-select-option
                v-for="instrument of instruments"
                :key="instrument.id"
                :value="instrument.id"
              >
                {{ instrument.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.transaction_no.$error }">
            <ion-label position="floating">Transaction No</ion-label>
            <ion-input
              type="text"
              v-model="transaction_no"
              :disabled="disabledField"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="floating">Transaction Date</ion-label>
            <ion-datetime
              type="text"
              v-model="transaction_date"
              placeholder="Select Date"
              :disabled="disabledField"
            ></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.bank.$error }">
            <ion-label position="floating">Bank</ion-label>
            <ion-select v-model="bank" :value="bank" :disabled="disabledField">
              <ion-select-option
                v-for="bank of banks"
                :key="bank.id"
                :value="bank.id"
              >
                {{ bank.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.remarks.$error }">
            <ion-label position="floating">Remarks</ion-label>
            <ion-input type="text" v-model="remarks"></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonListHeader,
  IonInput,
  IonDatetime,
  IonText,
  IonIcon,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import {
  required,
  requiredIf,
  maxLength,
  integer,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonListHeader,
    IonInput,
    IonDatetime,
    IonText,
    IonIcon,
  },
  data() {
    return {
      id: null,
      amount: null,
      transaction_no: null,
      transaction_date: null,
      instrument: null,
      bank: null,
      remarks: null,

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    instruments() {
      return this.$store.getters["attribute/instruments"];
    },
    banks() {
      return this.$store.getters["attribute/banks"];
    },

    offlinePayment() {
      return this.$store.getters["offlinePayment/showResource"];
    },

    disabledField() {
      const instrument = this.instrument;
      if (instrument == "1" || instrument == null) {
        return true;
      }
      return false;
    },
  },

  watch: {
    instrument(newValue) {
      if (newValue == "1") {
        this.bank = null;
        this.transaction_no = null;
        this.transaction_date = null;
      }
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      amount: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
        maxLength: helpers.withMessage("maximum 5 characters", maxLength(5)),
      },
      transaction_no: {
        requiredIfNotCash: helpers.withMessage(
          "is required",
          requiredIf(this.instrument != "1")
        ),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      transaction_date: {
        requiredIfNotCash: helpers.withMessage(
          "is required",
          requiredIf(this.instrument != "1")
        ),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      instrument: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      bank: {
        requiredIfNotCash: helpers.withMessage(
          "is required",
          requiredIf(this.instrument != "1")
        ),
        integer: helpers.withMessage("can only be integers", integer),
      },
      remarks: {
        requiredIfCash: helpers.withMessage(
          "is required",
          requiredIf(this.instrument == "1")
        ),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    async confirmUpdate() {
      const header = `Save payment record?`;
      const message = "This cannot be changed or modified after saving.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.update();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async setOfflinePayment() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }
      this.confirmUpdate();
    },

    async update() {
      let data = {
        id: this.id,
        amount: this.amount,
        transaction_no: this.transaction_no,
        transaction_date: this.transaction_date,
        instrument_id: this.instrument,
        bank_id: this.bank,
        remarks: this.remarks,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("offlinePayment/updateResource", data);
        await this.$store.dispatch("invoice/showResource", {
        resourceId: this.resource.id,
      });
        spinner.dismiss();
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async initDate() {
      const data = await this.offlinePayment.manualPayment;
      if (data == null) {
        return;
      }
      const date = data.transaction_date;
      if (date == null) {
        return;
      }
      this.transaction_date = date;
    },

    async fetchOfflinePayment() {
      await this.$store.dispatch("offlinePayment/showResource", {
        resourceId: this.resource.id,
      });
      this.id = this.offlinePayment.manualPayment.id;
      this.amount = parseInt(this.offlinePayment.manualPayment.amount_in_cent) 
        ? parseInt(this.offlinePayment.manualPayment.amount_in_cent)/100
        : null;
      this.transaction_no = this.offlinePayment.manualPayment.transaction_no;
      this.transaction_date = this.offlinePayment.manualPayment.transaction_date;
      this.remarks = this.offlinePayment.manualPayment.remarks;
    },

    async fetchBanks() {
      await this.$store.dispatch("attribute/banks");
      this.bank = this.offlinePayment.manualPayment.bank_id;
    },

    async fetchInstruments() {
      await this.$store.dispatch("attribute/instruments");
      this.instrument = this.offlinePayment.manualPayment.instrument_id;
    },
  },

  async created() {
    await this.fetchOfflinePayment();
    this.fetchInstruments();
    this.fetchBanks();
    this.initDate();
  },
};
</script>
